<template>
  <div>
    <div class="form">
      <div class="label">身份证号</div>
      <div class="value">
        <input
          type="text"
          placeholder="请输入身份证号"
          v-model="idCard"
          class="width-full"
        />
        <span class="clear" v-if="idCard != ''" @click="idCard = ''"></span>
      </div>
      <div class="label">手机号</div>
      <div class="value">
        <input
          type="number"
          placeholder="请输入手机号"
          v-model="telphone"
          class="width-full"
        />
        <span class="clear" v-if="telphone != ''" @click="telphone = ''"></span>
      </div>
      <div class="label">验证码</div>
      <div class="value flex-between">
        <input
          type="text"
          placeholder="请输入验证码"
          v-model="code"
          class="width-half"
          maxlength="6"
        />
        <button class="button-small" @click="onGetCaptcha()" v-if="!isDisabled">
          发送验证码
        </button>
        <button class="button-small-disabled" v-else>
          重新获取({{ seconds }}秒)
        </button>
      </div>
      <button
        class="button"
        @click="loginByUserInfo()"
        v-if="idCard && telphone && code"
      >
        案件查询
      </button>
      <button class="button disabled" v-else>案件查询</button>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
// import { loginByUserInfo } from "../services/api";
export default {
  name: "Login",
  components: {},
  props: {
    getCaptcha: {
      type: Function,
      require: true,
    },
    mpLogin: {
      type: Function,
      require: true,
    },
    detailPath: String,
  },
  data() {
    return {
      idCard: "",
      telphone: "",
      code: "",
      seconds: 59,
      isDisabled: false,
      // getCaptcha:this.getCaptcha,
    };
  },

  mounted() {
    // console.log('this.$route',this.$route);
  },
  methods: {
    onGetCaptcha() {
      if (
        !/^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(
          this.idCard
        )
      ) {
        Toast("请输入正确的身份证号");
        return;
      }
      if (!/^1[0-9]{10}$/.test(this.telphone)) {
        Toast("请输入正确的手机号");
        return;
      }
      this.getCaptcha({ mobile: this.telphone, idCard: this.idCard }).then(
        (res) => {
          if (res.success) {
            this.settime();
          } else {
            Toast(res.message);
          }
        }
      );
      // if (/^1[0-9]{10}$/.test(this.telphone)) {
      //   this.getCaptcha({ object: this.telphone }).then((res) => {
      //     if (res.success) {
      //       this.settime();
      //     } else {
      //       Toast(res.message);
      //     }
      //   });
      // } else {
      //   Toast("请输入正确的手机号");
      // }
    },

    settime() {
      if (this.seconds == 0) {
        this.isDisabled = false;
        this.seconds = 60;
        return;
      } else {
        this.isDisabled = true;
        this.seconds--;
      }
      setTimeout(() => {
        this.settime();
      }, 1000);
    },

    loginByUserInfo() {
      // this.$router.push(this.detailPath);
      if (
        !/^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(
          this.idCard
        )
      ) {
        Toast("请输入正确的身份证号");
        return;
      } else if (!/^1[0-9]{10}$/.test(this.telphone)) {
        Toast("请输入正确的手机号");
        return;
      } else if (!this.code) {
        Toast("请输入验证码");
        return;
      }
      this.mpLogin({
        mobile: this.telphone,
        idCard: this.idCard,
        code: this.code,
      }).then((res) => {
        if (res.success) {
          localStorage.setItem("tsToken", res.result.tsToken);
          this.$router.push(this.detailPath);
        } else {
          Toast(res.message);
        }
      });
    },
  },
};
</script>
<style scoped lang="less">
@import "./Login.less";
</style>
